































































































































































































































































































































































import {
  BPagination,
  BSpinner,
  BModal,
  BFormInput,
  BTr,
  BTh,
  BTable,
  BForm,
  BFormGroup,
  BFormFile,
  BButton,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import { required } from "@validations";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { VueSelect } from "vue-select";
import {
  ExtraFilter,
  ExtraFilterValueCreateUpdateModel,
} from "@/api/models/rapportActivites/rapportActivite";
import { TypeColonneEnum } from "@/api/models/enums/typeColonne";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BSpinner,
    BModal,
    DeleteIcon,
    BForm,
    BFormInput,
    BTh,
    BTr,
    BTable,
    BFormGroup,
    SearchableVueSelect,
    BFormFile,
    BButton,
    VueSelect,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    RessifnetDateInput,
  },
})
export default class DetailsRapportActiviteTemplate extends Vue {
  required = required;
  modalLoading: boolean = false;
  selectedFiltre: ExtraFilter | null = null;
  newValeur: ExtraFilterValueCreateUpdateModel = {
    libelle: undefined,
    valeur: undefined,
  };

  booleanOptions = [
    { value: "True", text: "Vrai" },
    { value: "False", text: "Faux" },
  ];
  typeColonneEnum = TypeColonneEnum;

  typesColonneOptions = [
    { value: TypeColonneEnum.Boolean, text: "Booléen" },
    { value: TypeColonneEnum.Numeric, text: "Numérique" },
    { value: TypeColonneEnum.Text, text: "Texte" },
    { value: TypeColonneEnum.Date, text: "Date" },
  ];

  typesGraphOptions = [
    { value: TypeGraphEnum.Individuel, text: "Individuel" },
    { value: TypeGraphEnum.Collectif, text: "Collectif" },
  ];

  async created() {
    await this.loadExtraFilter();
  }

  async loadExtraFilter() {
    try {
      const response =
        await this.$http.ressifnet.rapportActivites.extraFilterById(
          this.$route.params.filtreId,
        );
      this.selectedFiltre = response;
    } catch (error) {
      this.handleApiError(
        "Une erreur s'est produite lors du chargement du filtre",
        error,
      );
    }
  }

  async updateExtraFilter() {
    try {
      await this.$http.ressifnet.rapportActivites.updateExtraFilter(
        this.$route.params.filtreId,
        this.selectedFiltre,
      );
      this.showSuccessAlert("Le filtre a bien été mis à jour");
      if (this.selectedFiltre?.hasValue) {
        await this.updateValeurs();
      }
      await this.loadExtraFilter();
    } catch (error) {
      this.handleApiError(
        "Une erreur s'est produite lors de la mise à jour du filtre",
        error,
      );
    }
  }

  async createValeur() {
    try {
      await this.updateExtraFilter();
      const response =
        await this.$http.ressifnet.rapportActivites.createExtraFilterValue(
          this.$route.params.filtreId,
          this.newValeur,
        );
      this.showSuccessAlert("La valeur a bien été ajoutée");
      await this.loadExtraFilter();
    } catch (error) {
      this.handleApiError(
        "Une erreur s'est produite lors de l'insertion de la valeur",
        error,
      );
    }

    this.newValeur = {
      libelle: undefined,
      valeur: undefined
    };
  }

  async updateValeurs() {
    const valeurs = this.selectedFiltre?.valeurs!;
    const updatePromises = valeurs.map(async (valeur) => {
      try {
        await this.$http.ressifnet.rapportActivites.updateExtraFilterValue(
          this.$route.params.filtreId,
          valeur.id,
          valeur,
        );
        return { success: true };
      } catch (error) {
        return { success: false };
      }
    });

    const results = await Promise.all(updatePromises);

    const isSuccess = results.every((result) => result.success);

    if (isSuccess) {
      this.showSuccessAlert(
        "Toutes les valeurs ont été mises à jour avec succès",
      );
    } else {
      this.showErrorAlert(
        "Une erreur s'est produite lors de la mise à jour de certaines valeurs",
      );
    }
  }

  async deleteValeur(valeurId: string) {
    try {
      const confirmValue = await this.$bvModal.msgBoxConfirm(
        "Etes-vous sûr de vouloir supprimer cette valeur ?",
        {
          title: "Confirmation de suppression",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        },
      );
      if (confirmValue === true) {
        await this.confirmDeleteValeur(valeurId);
      }
    } catch (error) {
      this.handleApiError(
        "Une erreur s'est produite lors de la confirmation de suppression",
        error,
      );
    }
  }

  async confirmDeleteValeur(valeurId: string) {
    try {
      await this.$http.ressifnet.rapportActivites.deleteExtraFilterValue(
        this.$route.params.filtreId,
        valeurId,
      );
      this.showSuccessAlert("Suppression effectuée avec succès");
      await this.loadExtraFilter();
    } catch (error) {
      this.handleApiError(
        "Une erreur s'est produite lors de la suppression de la valeur",
        error,
      );
    }
  }

  private showSuccessAlert(message: string) {
    successAlert.fire({
      title: "Succès",
      text: message,
    });
  }

  private showErrorAlert(message: string) {
    errorAlert.fire({
      title: "Erreur",
      text: message,
    });
  }

  private handleApiError(defaultMessage: string, error: any) {
    const errorMessage = error.message || defaultMessage;
    this.showErrorAlert(errorMessage);
  }
}
